import {PlatformPaths} from '@configurations/platform';
import {RouteObject} from '@core/routes';

export const routes: RouteObject[] = [
    {
        path: `${PlatformPaths.Projects.fullPath}/*`,
        lazy: async () => {
            const {ProjectsListPage} = await import('../pages/ProjectsListPage');
            return {Component: ProjectsListPage};
        },
    },
    {
        path: `${PlatformPaths.AddProject.fullPath}/*`,
        lazy: async () => {
            const {ProjectCreationPage} = await import('../pages/ProjectCreationPage');
            return {Component: ProjectCreationPage};
        },
    },
    {
        path: `${PlatformPaths.ViewProject.fullPath}/*`,
        lazy: async () => {
            const {ProjectViewPage} = await import('../pages/ProjectViewPage');
            return {Component: ProjectViewPage};
        },
    },
    {
        path: `${PlatformPaths.EditProject.fullPath}/*`,
        lazy: async () => {
            const {ProjectEditPage} = await import('../pages/ProjectEditPage');
            return {Component: ProjectEditPage};
        },
    },
];
